import { Navbar } from './components';
import { Header, About, Work, Skills, Testimonial, Footer } from './containers';

import './App.scss';

const App = () => {
  return (
    <div className='app'>
      <Navbar />
      <Header />
      <About />
      <Work />
      <Skills />
      <Testimonial />
      <Footer />
    </div>
  )
}

export default App;
